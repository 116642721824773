import { render, staticRenderFns } from "./CreateVulnerability.vue?vue&type=template&id=05879137&scoped=true&"
import script from "./CreateVulnerability.vue?vue&type=script&lang=ts&"
export * from "./CreateVulnerability.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05879137",
  null
  
)

export default component.exports