














































































































































































































































































































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import generalData from '@/assets/data/general-data.json'
import vulnerabilityLevels from '@/assets/data/vulnerability-data.json'
import variables from '@/shared/variables'
import apolloClient from '../../shared/services/ApolloCLientAPI'
import vulnerabilityQuery from '@/shared/queries/vulnerabilityQueries'
import Confirmation from '@/shared/components/Confirmation.vue'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
    },
    components: {
        Confirmation,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels: Ref = ref({})
        const levelExplanation: Ref = ref('')
        const editable = ref(false)
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const BIVdropdown: Ref = ref([])
        const vulLevelDropdown = ref([{}])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const saveType = ref('')
        const formData = ref({
            vulnerabilityExplanation: '',
            vulnerabilityLevel: 0,
            description: '',
            BIV: {
                B: YesNo.NO,
                I: YesNo.NO,
                V: YesNo.NO,
            },
            riskList: [],
        })
        const { role } = useState(['role'])
        const riskList: Ref = ref([])
        const errors = ref({
            vulnerabilityLevel: { missingValue: false },
            description: { missingValue: false },
        })
        const labelInfoOverlayPanel: Ref = ref(null)
        const infoContent: Ref<TranslateResult> = ref('')
        const labelInfomationOpconnectedRisk: Ref = ref(null)

        // computed properties

        /* Fields which super admin can edit, will be viewed if
            - form is not in superadmin editable mode (superAdminEdit value is `false`) and
            - when it's not a self created record which is in employee editable mode and
            - entityDetails are available (view details)
        */
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !(createdByUser.value && editable.value) &&
                props.entityDetails
            )
        })

        /* Fields which super admin can edit, will be editable if
            - form is in superadmin editable mode (superAdminEdit value is `true`) or
            - it's self created record which is in employee editable mode or
            - entityDetails unavailable (create new)
        */
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                superAdminEdit.value ||
                (createdByUser.value && editable.value) ||
                !props.entityDetails
            )
        })

        /* Fields which employee can edit, will be viewed if
            - form is in view mode (editable value is `false`) and
            - entity details available and
            - user is editing self created record
        */
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !editable.value && props.entityDetails && !(createdByUser.value && editable.value)
            )
        })

        /* Fields which employee can edit, will be editable if
            - `editable.value` enabled or
            - user is editing self created record
        */
        const isEmployeeEditFieldEnabled = computed(() => {
            return editable.value || !props.entityDetails || (createdByUser.value && editable.value)
        })

        /* display connected risk edit field if
            - page is create new threat or
            - user is editing self created record
        */
        const isConnectRiskEditEnabled = computed(() => {
            return (
                (!editable.value && !props.entityDetails) || (createdByUser.value && editable.value)
            )
        })

        /* page will be in editable mode if
            -  `superAdminEdit` or `editable` is enabled(property value `true`) or
            - `props.entityDetails` details are unavailable (create new)
        */
        const isEditableMode = computed(() => {
            return editable.value || superAdminEdit.value || !props.entityDetails
        })

        /* 'Save and next' button will display if
            - form is in editable mode( `superAdminEdit` or `editable`)
        */
        const isSaveNextBtnEnabled = computed(() => {
            return editable.value || superAdminEdit.value
        })

        /* 'Save' button will display if
            - form is in editable mode( `superAdminEdit` or `editable`) or
            - `props.entityDetails` details are unavailable (create new)
        */
        const isSaveBtnEnabled = computed(() => {
            return editable.value || superAdminEdit.value || !props.entityDetails
        })
        /* 'Next' and 'Previous' button will display if
            - form is not in editable mode( `superAdminEdit` or `editable`) and
            - `props.entityDetails` details are available (view)
        */
        const isNextPreviousBtnEnabled = computed(() => {
            return !editable.value && !superAdminEdit.value && props.entityDetails
        })

        /* 'Cancel' button will display if,
            - form is in either Employee edit or superadmin edit or 
            - `props.entityDetails` details are unavailable (create new) */
        const isCancelEnabled = computed(() => {
            return editable.value || superAdminEdit.value || !props.entityDetails
        })

        /* enable user(employee/perium admin or developer admin) edit button only when
        - form is not in super admin editable mode and
        - form is not in editable mode and
        - roles are `Employee`, `Perium admin` and `Developer admin` and
        - entity data is available(form is not in create mode) and
         */
        const isEmployeeEditButtonEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !superAdminEdit.value &&
                !editable.value &&
                roles.includes(role.value) &&
                props.entityDetails
            )
        })

        /* Display superadmin edit button if
            - `editable` is not enabled and
            - page is in view mode and
            - when the user role is only superadmin and
            - when the `entityDetails` details are available and
            - when the record is a system entered record
            */
        const isSuperAdminEditBtnEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !editable.value &&
                role.value === UserRole.SUPER_ADMIN &&
                !createdByUser.value &&
                props.entityDetails
            )
        })

        //  validating form
        const validateForm = () => {
            // missingValue checks
            errors.value.description.missingValue = utils.validateFields(
                formData.value.description,
                'string'
            )

            errors.value.vulnerabilityLevel.missingValue = utils.validateFields(
                formData.value.vulnerabilityLevel,
                'number'
            )
        }
        // set labels
        const setLabels = () => {
            labels.value = {
                description: t('VULNERABILITIES_TABLE_COLUMN_DESCRIPTION', language),
                vulnerabilityLevel: t(
                    'VULNERABILITIES_TABLE_EXPAND_VULNERABILITY_LEVEL',
                    language.value
                ),
                vulnerabilityExplanation: t(
                    'VULNERABILITIES_TABLE_COLUMN_VULNERABILITY_EXPLANATION',
                    language.value
                ),
                connectedRisks: t('CONNECTED_RISKS', language.value),
                impactAvailability: t(
                    'VULNERABILITIES_TABLE_COLUMN_IMPACT_AVAILABILITY',
                    language.value
                ),
                impactIntegrity: t('VULNERABILITIES_TABLE_COLUMN_IMPACT_INTEGRITY', language.value),
                impactConfidentiality: t(
                    'VULNERABILITIES_TABLE_COLUMN_IMPACT_CONFIDENTIALITY',
                    language.value
                ),
                owner: t('OWNER', language),
                vulnerabilityExplanationPlaceholder: t(
                    'VULNERABILITIES_TABLE_EXPAND_VUL_EXPLANATION_PLACEHOLDER',
                    language.value
                ),
                vulnerabilityLevelPlaceholder: t(
                    'VULNERABILITIES_TABLE_EXPAND_VULNERABILITY_LEVEL_PLACEHOLDER',
                    language.value
                ),
            }
        }

        //  format original data
        const setFormattedDetails = () => {
            if (!props.entityDetails) {
                return
            }
            levelExplanation.value = vulnerabilityLevels.VULNERABILITY_LEVELS.find(
                (val) =>
                    val.SCORE === (props.entityDetails && props.entityDetails.vulnerabilityLevel)
            )?.VALUE

            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        const setDropdownValues = () => {
            // set biv dropdown values
            BIVdropdown.value = []

            generalData.YESNO.map((biv) => {
                BIVdropdown.value.push({
                    key: biv.KEY,
                    value: t(biv.VALUE, language.value),
                })
            })
            // set dropdown values for vulnerability level
            const tempvulLevelDropdown = vulnerabilityLevels.VULNERABILITY_LEVELS

            vulLevelDropdown.value = []
            tempvulLevelDropdown.map((level) => {
                vulLevelDropdown.value.push({
                    explanation: t(level.VALUE, language.value),
                    score: level.SCORE,
                })
            })
        }

        // set data for form
        const setFormData = () => {
            const vulnerabilityDetails = props.entityDetails
            if (!vulnerabilityDetails) {
                return
            }
            formData.value = {
                vulnerabilityExplanation: vulnerabilityDetails.vulnerabilityExplanation,
                vulnerabilityLevel: vulnerabilityDetails.vulnerabilityLevel,
                BIV: {
                    B: vulnerabilityDetails.impactAvailability ? YesNo.YES : YesNo.NO,
                    I: vulnerabilityDetails.impactIntegrity ? YesNo.YES : YesNo.NO,
                    V: vulnerabilityDetails.impactConfidentiality ? YesNo.YES : YesNo.NO,
                },
                description: vulnerabilityDetails.description,
                riskList: vulnerabilityDetails.risks.map((risk: { id: number }) => risk.id),
            }
        }

        // get risks list
        const getRisks = async () => {
            const riskQuery = `
                query{
                    risks{
                        items{
                            ${vulnerabilityQuery.RISK_LIST}
                        }
                    }
                }
            `

            let result
            try {
                result = await apolloClient.getGraphqlData(riskQuery, null)
            } catch {
                throw Error('Error while retrieving risks')
            }

            if (result) {
                const risks = [...result.data.risks.items]
                // sort list by order
                risks.sort((a, b) => (a.order > b.order ? 1 : -1))
                risks.map((risk) => {
                    const riskCopy = Object.create(risk)
                    // make the label unique
                    riskCopy.label = risk.refId + '. ' + risk.description
                    riskList.value.push(riskCopy)
                })
            }
        }

        // go to record (previous/ next)
        const goToRecord = (actionType: string) => {
            emit('go-to-record', { no: props.entityDetails && props.entityDetails.no, actionType })
        }

        // enable edit fields
        const enableEdit = () => {
            if (role.value === UserRole.SUPER_ADMIN) {
                superAdminEdit.value = true
            } else {
                editable.value = true
            }
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false
            superAdminEdit.value = false
            if (props.entityDetails) {
                setFormData()
            } else {
                emit('close-create-new')
            }
        }

        // save vulnerability data
        const save = (sType: string) => {
            // validate form
            validateForm()
            // check if the form has errors
            if (utils.hasErrors(errors.value)) {
                return
            }

            if (props.entityDetails) {
                emit('save-vulnerability', {
                    id: props.entityDetails.id,
                    formData: formData.value,
                    createdBy: createdByUser.value,
                    saveType: sType,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-vulnerability', {
                    formData: formData.value,
                })
            }
        }
        // open save confirmation
        const openSaveConfirmation = (sType: string) => {
            saveType.value = sType
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(sType)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, content: string) => {
            infoContent.value = t(content, language.value)
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            labelInfoOverlayPanel.value.toggle(event)
        }

        // watch for language chaneges and update labels
        watch(
            language,
            () => {
                setLabels()
                setDropdownValues()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            await getRisks()
            if (props.entityDetails) {
                setFormattedDetails()
                setFormData()
            }
        })

        return {
            t,
            language,
            utils,
            labels,
            levelExplanation,
            goToRecord,
            editable,
            enableEdit,
            superAdminEdit,
            createdByUser,
            BIVdropdown,
            formData,
            vulLevelDropdown,
            cancelEdit,
            riskList,
            save,
            role,
            variables,
            displaySaveConfirmation,
            displayCancelConfirmation,
            saveType,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            errors,
            labelInfomationOpconnectedRisk,
            toggleInformation,
            UserRole,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            isConnectRiskEditEnabled,
            isSaveNextBtnEnabled,
            isSaveBtnEnabled,
            isNextPreviousBtnEnabled,
            isEditableMode,
            YesNo,
            labelInfoOverlayPanel,
            infoContent,
            isCancelEnabled,
            isEmployeeEditButtonEnabled,
            isSuperAdminEditBtnEnabled,
        }
    },
})
