//  vulnerability list 
const VULNERABILITIES_LIST = `
    id
    order
    refId
    alertColor{
        color
        info
        infoKey
    }
    description
    vulnerabilityLevel
    vulnerabilityExplanation
    `

const VULNERABILITY_DETAILS = `
    id
    order
    refId
    alertColor{
        color
        info
        infoKey
    }
    created
    description
    impactConfidentiality
    impactIntegrity
    impactAvailability
    lastModified
    owner{
        id
        username
        firstName
        lastName
    }
    created_by{
        id
        username
    }
    risks{
        id
        description
    }
    vulnerabilityLevel
    vulnerabilityExplanation
`

const RISK_LIST = `
    id
    order
    refId
    description
    fullDescription
    `
export default { VULNERABILITIES_LIST, VULNERABILITY_DETAILS, RISK_LIST }