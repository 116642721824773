



































































































































import apolloClient from '../../shared/services/ApolloCLientAPI'
import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import { ParamDTO, VulDTO } from '@/dto/backend-response/vulnerabilitiesDTO'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import vulQuery from '@/shared/queries/vulnerabilityQueries'
import variables from '@/shared/variables'
import CreateVulnerability from '@/components/vulnerability/CreateVulnerability.vue'
import VulnerabilityOperations from '@/components/vulnerability/VulnerabilityOperations.vue'
import vulData from '@/assets/data/vulnerability-data.json'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import { VulnerabilityFormDTO } from '@/dto/forms/vulnerabilityFormDTO'

export default defineComponent({
    components: { Table, CreateVulnerability, VulnerabilityOperations },
    props: {
        idfilter: {
            type: Number,
        },
    },
    setup(props) {
        const toast = useToast()
        const vulnerabilityList: Ref = ref([])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const infomationOp: Ref = ref(null)
        const { role } = useState(['role'])
        const displayCreateVulnerability = ref(false)
        const vulnerabilityDetails: Ref = ref(null)
        const visibleVulnerabilityDetails = ref(false)
        const tableCmp: Ref = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const vulFilters = vulData.VULNERABILITY_FILTERS
        const totalPages = ref(0)

        // computed properties

        /* Enable create new threat button when
           - logged in user role is Employee/ Perium Admin/ Developer admin
           - and is not archived list 
        */
        const isEnableCreateNewVulButton = computed(() => {
            const roles = [UserRole.EMPLOYEE, UserRole.PERIUM_ADMIN, UserRole.DEVELOPER_ADMIN]
            return roles.includes(role.value)
        })

        // updating vulnerabilityList array with additional fields and setting description content
        const toTableFormat = (vulnerabilities: Array<VulDTO>) => {
            vulnerabilityList.value = []
            vulnerabilities.map((vulnerability) => {
                // Vul is a tablerow compatible version of a Vulnerability
                let vul = {
                    no: 0,
                    color: '',
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                }
                // creating a copy of vulnerability object
                vul = Object.create(vulnerability)
                vul.no = utils.padLeft('000', vulnerability.refId)
                vul.color = utils.getAlertInfo('', vulnerability).color
                vul.info = utils.getAlertInfo('', vulnerability).info
                vul.infoKey = utils.getAlertInfo('', vulnerability).infoKey
                vul.alertColorName = utils.getAlertInfo('', vulnerability).alertColorName

                vulnerabilityList.value.push(vul)
            })
        }

        // format vulnerability details
        const getFormatedVulnerabilityDetails = () => {
            if (!vulnerabilityDetails.value) {
                return
            }
            // add vulnerability details color and info
            vulnerabilityDetails.value.color = utils.getAlertInfo(
                '',
                vulnerabilityDetails.value
            ).color
            vulnerabilityDetails.value.info = utils.getAlertInfo(
                '',
                vulnerabilityDetails.value
            ).info
            vulnerabilityDetails.value.infoKey = utils.getAlertInfo(
                '',
                vulnerabilityDetails.value
            ).infoKey
            vulnerabilityDetails.value.alertColorName = utils.getAlertInfo(
                '',
                vulnerabilityDetails.value
            ).alertColorName

            vulnerabilityDetails.value.no = utils.padLeft('000', vulnerabilityDetails.value.refId)
            // add `lastRecordNo` and `firstRecordNo` to enable/disable nex/previous buttons
            vulnerabilityDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            vulnerabilityDetails.value.firstRecordNo =
                tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get vulnerability by id
        const getVulnerability = async (id: number) => {
            vulnerabilityDetails.value = null
            displayCreateVulnerability.value = false
            const query = `
                query {
                    vulnerability(id: ${id}) {
                        ${vulQuery.VULNERABILITY_DETAILS}
                    }
                }
            `

            const result = await apolloClient.getGraphqlData(query)
            if (result) {
                vulnerabilityDetails.value = result.data.vulnerability
                getFormatedVulnerabilityDetails()
            }
        }

        // get all vulnerabilities
        const getVulnerabilities = async () => {
            loading.value = true
            const getVulQuery = `
                query {
                    vulnerabilities{
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items {
                            ${vulQuery.VULNERABILITIES_LIST}
                        }
                    }
                   
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getVulQuery)
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving vulnerabilities')
            }
            loading.value = false
            if (!result) {
                return
            }
            // Copy array to be able to sort it in place
            const vulnerabilities = [...result.data.vulnerabilities.items]
            // total pages based on perPage value
            totalPages.value = Number(result.data.vulnerabilities.pageInfo.totalPages)
            // display totale records count as filteredRecordCount. This could change based on client's comments in future
            filteredRecordCount.value = result.data.vulnerabilities.pageInfo.totalCount
            // sort by id (ascending)
            vulnerabilities.sort((a, b) => (a.refId > b.refId ? 1 : -1))
            // Update vulnerabilityList with additional fields and set foldout content
            toTableFormat(vulnerabilities)
        }

        // go to vulnerability record
        const goToRecord = async (params: { no: string; actionType: string }) => {
            if (!tableCmp.value) {
                return
            }
            /* get the index of selected details on `copyOfTableData`. 
            `vulnerabilityList` cannot be use here as when the filter/ sort is in use, list is getting updated */
            const record = utils.getRecordByIndex(
                tableCmp.value.copyOfTableData,
                params.actionType,
                params.no,
                tableCmp.value
            )
            // use the id of the record to get next/previous details
            await getVulnerability(record.id)
        }

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_NO', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'description',
                    limit:200,
                },
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_VULNERABILITY_LEVEL', language.value),
                    sort: true,
                    fieldName: 'vulnerabilityLevel',
                    level: true,
                    levelType: 'vulnerabilityLevel',
                    info: true,
                    infoContent: t(
                        'VULNERABILITIES_TABLE_COLUMN_VULNERABILITY_LEVEL_INFO',
                        language
                    ),
                },
            ]
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (!tableCmp.value.sortedField) {
                return
            }
            // Based on the sorted type before entity gets save and refresh the list, sort the refreshed list
            if (tableCmp.value.sortToggle === 'up') {
                vulnerabilityList.value.sort(
                    (a: { [x: string]: number }, b: { [x: string]: number }) =>
                        a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                )
            } else {
                vulnerabilityList.value.sort(
                    (a: { [x: string]: number }, b: { [x: string]: number }) =>
                        b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                )
            }
        }

        // get all vulnerability input fields
        const getAllVulInputs = (vulnerabilityFormData: VulnerabilityFormDTO) => {
            return {
                description: vulnerabilityFormData.description,
                impactAvailability: vulnerabilityFormData.BIV.B === YesNo.YES ? true : false,
                impactIntegrity: vulnerabilityFormData.BIV.I === YesNo.YES ? true : false,
                impactConfidentiality: vulnerabilityFormData.BIV.V === YesNo.YES ? true : false,
                vulnerabilityLevel: vulnerabilityFormData.vulnerabilityLevel,
                vulnerabilityExplanation: vulnerabilityFormData.vulnerabilityExplanation,
                risks: vulnerabilityFormData.riskList,
            }
        }

        // updating vulnerability data
        const saveVulnerability = async (params: ParamDTO) => {
            let mutationQuery
            let input
            const vulnerabilityFormData = params.formData
            if (role.value === UserRole.SUPER_ADMIN) {
                // mutation query for super admin
                mutationQuery = `
                        mutation ($input: UpdateMasterVulnerabilityInput!) {
                            updateMasterVulnerability(id: ${params.id}, input: $input) {
                                status
                            }
                        }
                    `
                input = {
                    description: vulnerabilityFormData.description,
                    impactAvailability: vulnerabilityFormData.BIV.B === YesNo.YES ? true : false,
                    impactIntegrity: vulnerabilityFormData.BIV.I === YesNo.YES ? true : false,
                    impactConfidentiality: vulnerabilityFormData.BIV.V === YesNo.YES ? true : false,
                }
            } else {
                // mutation query for other users
                mutationQuery = `
                        mutation ($input: VulnerabilityInput!) {
                            updateVulnerability(id: ${params.id}, input: $input) {
                                status
                            }
                        }
                    `

                // update input for the entities which are created by user
                if (params.createdBy) {
                    input = getAllVulInputs(vulnerabilityFormData)
                } else {
                    // update input for the entities which are created by organization
                    input = {
                        vulnerabilityLevel: vulnerabilityFormData.vulnerabilityLevel,
                        vulnerabilityExplanation: vulnerabilityFormData.vulnerabilityExplanation,
                    }
                }
            }

            // update data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('VULNERABILITY_UPDATE_ERROR_MESSAGE', language.value))
                throw Error('Error while updating vulnerability')
            }
            // other user updates
            if (result.data.updateVulnerability && result.data.updateVulnerability.status) {
                await getVulnerabilities()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    await getVulnerability(params.id)
                }
                toast.success(t('VULNERABILITY_UPDATE_SUCESS_MESSAGE', language.value))
            } else if (
                // super admin updates
                result.data.updateMasterVulnerability &&
                result.data.updateMasterVulnerability.status
            ) {
                await getVulnerabilities()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.saveType === 'save-next') {
                    await goToRecord({ no: params.no, actionType: 'next' })
                } else {
                    await getVulnerability(params.id)
                }
                toast.success(t('VULNERABILITY_UPDATE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('VULNERABILITY_UPDATE_ERROR_MESSAGE', language.value))
            }
        }

        //  create new vulnerability save
        const saveNewVulnerability = async (params: ParamDTO) => {
            const vulnerabilityFormData = params.formData

            const mutationQuery = `
                mutation ($input: VulnerabilityInput) {
                    createVulnerability(input: $input) {
                        status
                    }
                }`

            const input = getAllVulInputs(vulnerabilityFormData)

            // create vulnerability api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('VULNERABILITY_CREATE_ERROR_MESSAGE', language.value))
                throw Error('Error while creating the vulnerability')
            }

            if (result.data.createVulnerability && result.data.createVulnerability.status) {
                toast.success(t('VULNERABILITY_CREATE_SUCESS_MESSAGE', language.value))

                await getVulnerabilities()
                displayCreateVulnerability.value = false
            } else {
                toast.error(t('VULNERABILITY_CREATE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        const openVulnerabilityDetailsPopup = async (id: number) => {
            visibleVulnerabilityDetails.value = true
            await getVulnerability(id)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count: number) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount: number) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count: number) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // make active row if idfilter value is available within initial vulnerabilityList
        const activeRow = () => {
            if (
                vulnerabilityList.value.find(
                    (val: { id: number | undefined }) => val.id === props.idfilter
                )
            ) {
                const rowId = vulnerabilityList.value.find(
                    (val: { id: number | undefined }) => val.id === props.idfilter
                ).no
                tableCmp.value.activeRow(rowId)
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (visibleVulnerabilityDetails.value) {
                        visibleVulnerabilityDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateVulnerability.value) {
                        displayCreateVulnerability.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, () => {
            setTableHeaders()
        })

        onMounted(async () => {
            closeSidebarEsc()
            // when navigating from heatmap, open selected row details
            if (props.idfilter) {
                await getVulnerabilities()
                // make active row if idfilter value is available within initial vulnerabilityList
                activeRow()
                // open details page
                openVulnerabilityDetailsPopup(props.idfilter)
            } else {
                getVulnerabilities()
            }
            setTableHeaders()
        })

        return {
            vulnerabilityList,
            tableHeaders,
            language,
            t,
            saveVulnerability,
            loading,
            toggleInformation,
            infomationOp,
            displayCreateVulnerability,
            saveNewVulnerability,
            getVulnerabilities,
            variables,
            role,
            utils,
            openVulnerabilityDetailsPopup,
            vulnerabilityDetails,
            visibleVulnerabilityDetails,
            goToRecord,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            tableCmp,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            vulFilters,
            UserRole,
            totalPages,
            isEnableCreateNewVulButton,
        }
    },
})
