



















































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import LevelBar from '@/shared/components/LevelBar.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import VulnerabilityDetails from '@/components/vulnerability/VulnerabilityDetails.vue'
import removeMd from 'remove-markdown'
import vulnerabilityLevels from '@/assets/data/vulnerability-data.json'
import variables from '@/shared/variables'
import Heatmap from '@/shared/components/Heatmap.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        LevelBar,
        VulnerabilityDetails,
        Heatmap,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const copyOfEntityDetails = ref({})
        const levelExplanation: Ref = ref('')
        const TabviewCmp = ref(null)
        const nameInitals: Ref = ref('')
        const createdByUser = ref(false)

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)

            // vulnerability level explanation
            levelExplanation.value = vulnerabilityLevels.VULNERABILITY_LEVELS.find(
                (val) => val.SCORE === props.entityDetails.vulnerabilityLevel
            )?.VALUE
            // find the record is created by user or system
            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            labels,
            copyOfEntityDetails,
            levelExplanation,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
            createdByUser
        }
    },
})
